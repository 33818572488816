import PropTypes from "prop-types";
import React from "react";

import Geocoding from "../components/geocoding/Geocoding";
import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";

function GeocodingPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps", "API", "Geocoding"]}
          location={location}
          metaDescription="Géolocalisez vos services et permettez à vos utilisateurs de les trouver sur une carte."
          title="Géocoding"
        />
        <Page>
          <Geocoding />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

GeocodingPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default GeocodingPage;
